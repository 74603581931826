<template>
  <div class="row">
    <div class="col-12">
      <card type="contributions">
        <h4 class="card-title">Panel de configuraciones</h4>

        <hr />

        <template slot="footer">
          <div class="row">
            <div
              v-for="(item, index) in menus.filter(x=>x.level == 2 && x.canRead == true)"
              :key="index"
              class="col-12 col-sm-6 col-md-4 col-lg-3"
            >
              <router-link class="card-item" :to="{name: item.pathName}">
                <i class="far fa-building fa-2x mr-3"></i>
                <span>{{item.name}}</span>
              </router-link>
            </div>

            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <router-link class="card-item" :to="{name: 'departments'}">
                <i class="far fa-building fa-2x mr-3"></i>
                <span>Areas</span>
              </router-link>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <router-link class="card-item" :to="{name: 'companies'}">
                <i class="far fa-building fa-2x mr-3"></i>
                <span>Empresas</span>
              </router-link>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <router-link class="card-item" :to="{name: 'mailTemplateTypes'}">
                <i class="fas fa-mail-bulk fa-2x mr-3"></i>
                <span>Tipo Plantilla Email</span>
              </router-link>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <router-link class="card-item" :to="{name: 'mailTemplates'}">
                <i class="fas fa-mail-bulk fa-2x mr-3"></i>
                <span>Plantilla Email</span>
              </router-link>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <router-link class="card-item" :to="{name: 'projects'}">
                <i class="fas fa-project-diagram fa-2x mr-3"></i>
                <span>Proyectos</span>
              </router-link>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <router-link class="card-item" :to="{name: 'users'}">
                <i class="fas fa-users fa-2x mr-3"></i>
                <span>Usuario</span>
              </router-link>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
              <router-link class="card-item" :to="{name: 'roles'}">
                <i class="fas fa-project-diagram fa-2x mr-3"></i>
                <span>Roles</span>
              </router-link>
            </div>-->
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      menus: state => state.menus
    })
  },
  methods: {
    itemsContains(n) {
      return this.items.indexOf(n) > -1;
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  .card-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    border: 1px solid #ba54f5;
    padding: 0.5rem;
    &:hover {
      border: 1px solid #1d8cf8;
    }
  }
}
</style>
